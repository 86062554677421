export interface BlogInterface {
  title: string;
  description: string;
  routerLink: string;
}

export const BLOG_LIST: BlogInterface[] = [
  {
    title: 'blog.title1',
    description: 'Cilet jane faktoret qe percaktojne vleren e prones tuaj',
    routerLink: '/blog/vlera-e-prones',
  },
  {
    title: 'blog.title2',
    description: 'Si te kerkoni ne Homezone',
    routerLink: '/blog/keshilla-per-shqiperi',
  },
  {
    title: 'blog.title3',
    description: 'Cfare eshte situata e pasurive te paluajtshme ne Shqiperi',
    routerLink: '/blog/situata-e-pronave',
  },
  {
    title: 'blog.title4',
    description: 'Keshilla per agjentet imobiliar',
    routerLink: '/blog/keshilla-per-agjentet',
  },
  // {
  //   title: 'Si te sheses 5 prona ne 5 dite',
  //   description: 'Keshillat me te mira per te shitur pronat shpejt',
  //   routerLink: '/blog/5-prona-ne-5-dite',
  // },

  // {
  //   title: 'Dilema: Shtepi private apo Apartament?',
  //   description: 'Cilat jane avantazhet e nje shtepie private apo nje apartamenti',
  //   routerLink: '/blog/shtepi-private-apo-apartament',
  // },
];
